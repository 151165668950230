export default {
  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      justifyContent: 'center',
      alignItems: 'center',
      position: 'static',
      padding: '0.5rem',
    },
    '.containerScrolled ': {
      justifyContent: 'center',
      alignItems: 'center',
      background: 'unset',
      backgroundColor: 'black',
      boxShadow: '2px 2px 10px black',
    },
    '.logo': {
      position: 'static',
      transform: 'unset',
      img: {
        maxWidth: 'unset',
        maxHeight: ['70px', '', '', '120px'],
      },
    },
    '.logoScrolled': {
      position: 'static',
      transform: 'unset',
      a: {
        img: {
          maxWidth: 'unset',
          maxHeight: ['60px', '', '', '80px'],
        },
      },
    },
    '.smallNavMenu > div': {
      '.navItem': {},
    },
    '.navItem': {
      display: 'none',
    },
    '.hamburger': {
      display: 'none',
    },

    '.navMenu': {
      display: 'none',
    },

    '.navMenuLogo': {},
    '.navMenuOpen': {
      '.seperatorLine': {},
      '.navItem': {},
    },
    '.navBlockOpen': {},
    '.phoneSocialContainer': {},
    '.socialIconsContainer, .phoneContainer': {
      '.smallNavHeading': {},
    },
  },

  footer: {
    '.socialContainer, .contactDetails-container': {},
    '.sectionHeading': {},
    '.poweredBy .gonationLogo': {},
    '.copyright': {},
    '.multiButtonContainer': {},
    // display: 'none !important',
    color: 'white',
    backgroundColor: '#000000',
    borderTop: '3px solid',
    borderColor: 'primary',
    '.columnNav': {
      display: 'none',
    },
    '.poweredByText': {
      margin: ['0 auto', '', '', '0rem 0rem 0rem auto'],
    },
  },

  ctaWidget: {
    zIndex: '999',
    color: 'light',
    a: {
      border: 'none !important',
      display: 'none',
    },
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {},

  subtitle: {},

  text: {},

  sideBySide1: {
    '.lazyload-wrapper': {},
    '.content': {},
    '.title': {
      variant: 'customVariants.title',
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
    },
    '.text': {
      variant: 'customVariants.text',
    },
    a: {
      order: '4',
    },
    '.ctaButton': {
      variant: 'buttons.secondary',
    },
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    '.lazyload-wrapper': {
      order: '2',
    },
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    height: ['60vh', '', '', '85vh'],
    overflow: 'hidden',
    '.title': {
      letterSpacing: '3px',
      fontSize: ['1.75rem', '', '2rem', '2.5rem'],
      display: 'none',
    },
    '.subtitle': {
      fontFamily: 'Source Sans Pro Italic, sans-serif !important',
      fontStyle: 'italic',
    },
    '.ctaButton': {
      fontWeight: 'bold',
      ':hover': {
        backgroundColor: 'white',
        color: 'black',
      },
    },
    '.hero_content_container': {},
    '.slick-slide img': {
      height: '100%',
      objectFit: ['contain', '', 'cover'],
      // filter: 'brightness(0.8)',
    },
    '.hero_content_container': {
      color: 'white',
      width: '100%',
      maxWidth: '767px',
      margin: '0px',
      padding: '0rem',
      bottom: '0rem',
      top: 'unset',
      transform: 'translateX(-50%)',
    },
    '.slick-initialized .slick-slide > div': {
      height: ['50vh', '', '', '75vh'],
    },
    '.slick-arrow': {
      zIndex: '100',
      color: 'black',
      padding: ['0.25rem', '', '0.75rem'],
      width: ['25px', '', '50px'],
      height: ['25px', '', '50px'],
      ':hover': {
        color: 'black',
        opacity: '0.5',
      },
      clipPath:
        'polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);',
      backgroundColor: 'primary',
    },
    '.slick-prev': {
      left: '0px',
    },
    '.slick-next': {
      right: '0px',
    },
    '.slick-dots li button:before': {
      fontSize: '20px',
      color: '#f4c200',
    },
    '.slick-dots li.slick-active button:before': {
      color: '#f4c200',
    },
    '.ctaButton': {
      variant: 'buttons.primary',
      color: 'black',
      borderRadius: '10px',
      ':hover': {
        color: 'white',
      },
    },
  },
  homepageHeroShout: {
    display: 'none',
  },

  aboutSection: {
    padding: ['4rem 1rem', '', '6rem 1rem 3rem'],
    background: 'linear-gradient(90deg, #414141bf, #9f9f9f00)',
    '.title': {
      order: '1',
      display: 'flex',
      alignItems: 'center',
      alignSelf: ['flex-start', 'flex-start', 'center'],
      // '::before': {
      //   content: "''",
      //   marginRight: '1rem',
      //   width: '40px',
      //   height: '35px',
      //   backgroundColor: 'primary',
      //   clipPath:
      //     'polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)',
      // },
      fontSize: ['1.2rem', '1.5rem', '2rem', '2.5rem'],
      fontFamily: 'body',
      lineHeight: '1.5',
      letterSpacing: '2px',
    },
    '.subtitle': {
      order: '2',
      textAlign: ['left', '', 'center'],
      marginBottom: '2rem',
      borderBottom: '2px dotted #f4c200',
      padding: '0rem 2rem 1.5rem',
      fontWeight: '300',
      fontFamily: 'body',
    },
    '.text': {
      order: '3',
      fontWeight: '200',
      fontSize: ['1rem', '1.25rem', '1.25rem', '1.25rem'],
      color: '#eaeaea',
      p: {
        fontWeight: '200',
      },
      strong: {
        color: 'primary',
      },
    },
    a: {
      order: '4',
      borderRadius: '5px',
      color: 'black',
      fontWeight: 'bold',
      border: 'solid 1px',
      borderColor: 'primary',
      ':hover': {
        backgroundColor: 'black',
        color: 'white',
      },
    },
  },

  aboutSection2: {
    variant: 'customVariants.aboutSection',
    padding: ['6rem 1rem', '', '6rem 3rem', '6rem 15vw'],
    background: 'unset',
    backgroundColor: 'white',
    '.title': {
      color: 'black',
    },
    '.subtitle': {
      color: 'black',
    },
    '.text': {
      color: 'black',

      p: {
        color: 'black',
      },
    },
    '.image': {
      objectFit: 'contain',
    },
    '.ctaButton': {
      backgroundColor: 'primary',
    },
  },

  homepageShout: {
    margin: ['', '', '', '0rem'],
    backgroundColor: 'primary',
    '.content': {
      color: 'light',
      padding: ['4rem 1rem', '', '', '4rem 1rem'],
      position: 'relative',
    },
    '.title': {
      color: 'light',
      fontSize: '1.5rem',
      textAlign: ['left', 'center', 'center', 'center'],
      textDecoration: 'underline',
      fontSize: '1.4rem',
      color: '#373737',
      fontWeight: 'bold',
    },
    '.date': {
      textAlign: ['left', 'center', 'center', 'center'],
      opacity: '0.6',
      color: 'black',
    },
    '.text': {
      textAlign: ['left', 'center', 'center', 'center'],
      lineHeight: '1.6',
      fontFamily: 'Merriweather',
      fontSize: ['1.25rem', '1.5rem', '1.75rem'],
      maxWidth: '900px',
      color: '#373737',
      fontWeight: 'bold',
      letterSpacing: '1px',
    },
  },

  locationBoxes: {
    ':before': {
      content: "'Participating Restaurants'",
      textAlign: ['center', 'center', 'center', 'center'],
      textDecoration: 'underline',
      color: 'white',
      fontFamily: 'body',
      fontWeight: 'bold',
      width: '100%',
      fontSize: '2rem',
      textTransform: 'uppercase',
      letterSpacing: '2px',
      textDecoration: 'none',
      marginBottom: '2rem',
    },
    '.title': {
      display: 'flex',
      alignItems: 'center',
      alignSelf: ['center', 'center', 'center'],
      justifyContent: 'center',
      '::before': {
        content: "''",
        marginRight: '1rem',
        display: 'inline-table',
        width: '20px',
        height: '15px',
        backgroundColor: 'primary',
        clipPath:
          'polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)',
      },
    },
  },

  homepageGallery: {
    minHeight: '100px',
    backgroundColor: 'primary',
    borderTop: 'solid 2px',
    borderColor: 'primary',
    '.textContent': {
      display: 'none',
    },
    '.slick-dots': {
      display: 'none!important',
    },
    '.slick-arrow': {
      color: 'secondary',
    },
  },

  // homepageAbout: {
  //   variant: 'customVariants.sideBySide1',
  // },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  // ? ========================
  // ? =====  Menu page  =====
  // ? ========================

  menu: {
    '.menuContainer': {},
    '.tabsRow': {},
    '#menuTab': {
      ':hover': {},
    },
    '.menuItemInnerContainer, .menuItemContentContainer': {},
    '.menuSectionTitle': {},

    '.menuItemName': {},

    '.menuSectionDescription': {},
    '.menuItemDescription': {},
    '.menuPriceContainer': {},
    '.variantsContainer': {},
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumsContainer': {},
    '.albumTitle': {},
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    padding: '2rem 1rem',
    backgroundColor: '#202020',
    '.textContent': {
      display: 'none',
    },
    '.no-events-container': {},
    '.eventItemContainer': {
      width: ['100%', 'calc(50% - 2%)', 'calc(33.3% - 2%)'],
    },
    '.eventItemImage': {
      objectFit: 'contain',
    },
    '.eventDays, .eventDate': {},
    '.modal': {
      backgroundColor: 'black',
    },
    '.title': {
      fontFamily: 'body',
    },
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    '.title': {
      variant: 'customVariants.title',
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
    },
    '.text': {
      variant: 'customVariants.text',
    },
    form: {
      input: {},

      textarea: {},
      '.submitBtn': {
        variant: 'buttons.secondary',
      },
    },
  },

  locationMap: {
    order: '2',
    h3: {
      variant: 'customVariants.title',
    },

    '.dayofWeekText': {},
  },

  // ? ========================
  // ? ====  Press Menu  ====
  // ? ========================

  pressMenu: {},

  pressPage: {},

  // ? ========================
  // ? ====  Team Menu  ====
  // ? ========================

  teamMenu: {},

  teamPage: {},

  // ? ========================
  // ? ====  blog Menu  ====
  // ? ========================

  blogMenu: {},

  blogPage: {},
}
